import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import c1 from "../../../static/img/incursion-mercado/c1.jpg"
import c2 from "../../../static/img/incursion-mercado/c2.jpg"
import c3 from "../../../static/img/incursion-mercado/c3.jpg"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Incursión a los mercados"/>
            <Banner title="Incursión a los mercados"/>
            <section className="about_consulting_area bg-white pt-5 pb-5">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-7 order-1 order-lg-0">
                            <div className="ex_content">
                                <h3>Perfil del cliente</h3>

                                <p>Una exitosa empresa del sector de transportación
                                    marítima,
                                    que aumentó substancialmente su tráfico de buques cisterna gracias al fuerte aumento
                                    en
                                    los
                                    precios del petróleo crudo entre 2009 y 2014, planea adquirir una nueva flotilla en
                                    los
                                    próximos 3
                                    años. Por esta razón, su CFO recomendó al CEO (socio mayoritario y Presidente
                                    Ejecutivo)
                                    explorar
                                    un conocimiento más profundo de los mercados, desarrollar una red más amplia de
                                    inversionistas
                                    institucionales, así como fortalecer sus capacidades de cumplimiento regulatorio y
                                    gobierno
                                    corporativo. Lo anterior, con la finalidad de contar con una estructura de capital
                                    más
                                    competitiva
                                    que le permita fondear la compra de la flotilla de buques a un menor costo, sin
                                    presionar los
                                    flujos de efectivo de la Compañía.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 order-0 order-lg-1 text-center">
                            <img className="video_img wow fadeInRight" src={c1}
                                 alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about_consulting_area bg-white">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-5">
                            <img className="video_img w-100 wow fadeInLeft" src={c2}
                                 alt={" "}/>
                        </div>
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <h3>Mandato</h3>

                                <p>A través de la recomendación de un cliente de
                                    IRStrat, contratan
                                    la solución de Programa de Incursión, con el mandato de ayudar a definir el
                                    instrumento
                                    a emitir y la
                                    estrategia más efectiva go-to-market, así como generar las capacidades necesarias en
                                    la
                                    Compañía para
                                    poder ejecutar la colocación.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-5 pb-0">
                <div className="container ex_content">
                    <div className="row m-0">
                        <div className="col-md-12 pl-3 pl-lg-0">
                            <h3 className="s_title d-block">Solución</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="executive_area pt-3 pb-4">
                <div className="container">
                    <div className="row step service_right_sidebar p-0 mb-5 d-flex align-items-center">
                        <div className="col-lg-7">
                            <div id="accordion7" role="tablist" aria-multiselectable="true"
                                 className="panel-group faq-accordion service_accordion">
                                <div className="card active">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion7" href="#collapse25"
                                               className="btn-accordion"
                                               aria-expanded="true" role="button" aria-controls="collapse25">
                                                <span className="pluse">+</span><span className="minus">-</span>Estrategia
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse25" className="collapse show" aria-expanded="true" role="tabpanel">
                                        <div className="card-block panel-body"><p>IRStrat conduce una evaluación inicial
                                            de las capacidades
                                            de
                                            emisión del cliente, posteriormente, utilizando la información recabada,
                                            prepara una lista de
                                            verificación ad hoc de los objetivos a alcanzar, y define la agenda de
                                            trabajo más adecuada de
                                            acuerdo a las actividades regulares de la empresa.</p>
                                            <p> Nuestro equipo, de la mano de los principales ejecutivos, comienza a
                                                desarrollar la tesis de
                                                inversión, historia y ventaja competitiva de la Compañía, así como a
                                                diseñar la estructura del
                                                departamento de RI y esquematizar las funciones de preparación de
                                                información financiera. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse26"
                                               className="btn-accordion collapsed" aria-expanded="false"
                                               aria-controls="collapse26">
                                                <span className="pluse">+</span><span className="minus">-</span>Táctica
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse26" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">Se conduce un entrenamiento exhaustivo del equipo
                                            ejecutivo de nuestro
                                            cliente en cuestiones de finanzas, comunicación y marco regulatorio. Se
                                            desarrolla el reporte
                                            trimestral de resultados, el informe anual, la presentación corporativa y el
                                            sitio de RI, así como
                                            las políticas de gobierno corporativo de la compañía, y después de ejecutar
                                            una inteligencia
                                            puntual del sector y el mercado, se realizan acercamientos con posibles
                                            agentes colocadores,
                                            inversionistas, calificadoras y medios especializados.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse27"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span className="minus">-</span>Resultados
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse27" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">Gracias a las habilidades de negociación y capacidad
                                            de gestión de
                                            IRStrat, nuestro cliente recibe múltiples propuestas de colocación de 500 a
                                            650 millones de pesos
                                            en CEBURES, con un costo financiero 250 a 350 puntos básicos por debajo de
                                            la segunda mejor
                                            opción, en este caso, un crédito sindicado. Asimismo, nuestro cliente
                                            desarrolla las capacidades
                                            para cumplir con la regulación aplicable del mercado de valores en México,
                                            las mejores prácticas
                                            de gobierno corporativo y el alcance de preparación de reportes para poder
                                            llevar a cabo la
                                            colocación de CEBURES. También se preparan las bases para una subsecuente
                                            emisión de eurobonos en
                                            los mercados de deuda internacional o de un IPO en los mercados de
                                            capitales.
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-5 text-center mt-4 mt-lg-0">
                            <img className="img-fluid w-100 wow fadeInRight" src={c3}
                                 alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
